export default class Accordion {
  constructor() {

    const jsOpen = '.js-open';
    const isClose = 'is-close'
    const elem = '.sec-accordion-button';
    const tggleText = '.sec-accordion-button-txt';

    var sp_flag = false;
    // リサイズ時に取得
    $( window ).on( 'load resize', function() {

        sp_flag = ( window.matchMedia( '(max-width:768px)' ).matches );

        if( !sp_flag ) {

          $(jsOpen).next().css('display', 'block');

        }else{

          $(jsOpen).next().css('display', 'none');
          $(jsOpen).find(elem).removeClass(isClose);
          $(jsOpen).find(tggleText).html('開く');

        }

    } );

    $(function(){
      $(jsOpen).on( 'click', function() {

        if ( sp_flag ) {

          $(this).find(elem).toggleClass(isClose);
          $(this).next().slideToggle();

          if($(this).find(elem).hasClass(isClose)){

            $(this).find(tggleText).html('閉じる');
          }else{

            $(this).find(tggleText).html('開く');
          }

        }

      });

      $('.sec-accordion-anchor-link').on( 'click', function() {

        if ( sp_flag ) {

          $(this).parent().parent().parent().slideToggle();
          $(this).parent().parent().parent().prev().find(elem).toggleClass(isClose);
          $(this).parent().parent().parent().prev().find(tggleText).html('開く');

        }

      });

    });


  }



}
