//import MicroModal from 'micromodal';

export default class Modal {
    constructor() {

      var sX_syncerModal = 0 ;
      var sY_syncerModal = 0 ;

      function scrollposition(){

        var dElm = document.documentElement , dBody = document.body;
        sX_syncerModal = dElm.scrollLeft || dBody.scrollLeft;
        sY_syncerModal = dElm.scrollTop || dBody.scrollTop;
      }

      $('.js-modal-open').on('click', function(){
        scrollposition();
        var target = $(this).data('target');
        var modal = document.getElementById(target);
        this.scrollPosition = $(window).scrollTop();

        $('body').addClass('fixed').css({'top': -this.scrollPosition});
        $(modal).fadeIn();
        return false;
      });

      // モーダルウィンドウを閉じる
      $('.js-modal-close').on('click', function(){

        $('body').removeClass('fixed');
        //スクロール位置を戻す
        window.scrollTo( sX_syncerModal , sY_syncerModal );
        //window.scrollTo( 0 , this.scrollPosition );
        $('.js-modal').fadeOut();
        return false;
      });
    }


}


