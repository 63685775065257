

export default class NewsPageNav {
    constructor() {

      // URL を取得する（対象は第3階層で判別）
      const urlPath = location.pathname;
      const result = urlPath.split('/').filter(Boolean);

      //第３階層か判別
      const PathDir = location.href.split("/");
      const Pathdir2 = PathDir[PathDir.length -1];
      console.log(Pathdir2);


      //ページ一覧のナビゲーションを取得
      this.navNewsLinks = document.querySelectorAll('.news-nav_link');

      //第３階層が存在したら
      if(!Pathdir2){

        if(result){

          //news一覧のナビゲーション
          for(let i = 0; i < this.navNewsLinks.length; i++){
            let targetnavNewsLinks = this.navNewsLinks[i].getAttribute('href').split('/').filter(Boolean);

            let nowPageDir = targetnavNewsLinks[1];

            if(result[1] == nowPageDir){

              this.navNewsLinks[i].classList.add('is-show')

            }

          }

        }

      }else{
        //階層がなかったら/news/
        this.navNewsLinks[0].classList.add('is-show')

      }


    }


}
