//import MicroModal from 'micromodal';

export default class Contact {
  constructor() {
    this.submitCheckLabel = document.querySelector('.form-submit-check label')
    this.submitCheck = document.querySelector('.form-submit-check input')
    this.submitButton = document.querySelector('.form-submit-button')
    this.isConfirm = document.querySelector('.mw_wp_form_confirm')

    this.textarea = document.querySelector('textarea');



    if(this.submitCheckLabel) {
        this.submitCheckControll()
    }
    if(this.isConfirm) {
        this.submitButton.classList.remove('c-button--disabled')
    }

    if(this.textarea){
      this.checkForm()
    }


  }

  submitCheckControll() {
      //const el = '<span class="require">必須</span>'
      window.addEventListener('pageshow', ()=> {
          $(".privacy-agree").prop("checked",false);
      })
      //this.submitCheckLabel.insertAdjacentHTML('beforeend', el);
      this.submitCheckLabel.addEventListener('click', () => {
          if(this.submitCheck.checked) {
              this.submitButton.classList.remove('c-button--disabled')
          } else {
              this.submitButton.classList.add('c-button--disabled')
          }
      })
  }

  checkForm(){


    this.textarea.addEventListener('input', () => {
      if(!this.textarea.value || !this.textarea.value.match(/\S/g)){
        this.textarea.classList.remove("text-input");
        return false;
          }else{
            this.textarea.classList.add("text-input");
        return true;
      }
    })

}

}


