import Swiper from 'swiper/js/swiper.min.js';

export default class Slider {
  constructor() {

      var sliderThumbnail = new Swiper('.slider-thumbnail', {
        slidesPerView: 'auto',
        freeMode: false,
        loop: false,
        allowTouchMove: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });

      var slider = new Swiper('.slider', {
        slidesPerView:1.63,
        spaceBetween: 40,
        autoplay:true,
        speed: 500,
        //effect: coverflow,
        centeredSlides:true,
        loop:true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: {
          swiper: sliderThumbnail
        }
      });

  }

}
