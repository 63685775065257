export default class HeaderHorizonScroll {
  constructor() {
      this.headerElem = document.getElementById('header')
      this.gNav = document.getElementById('global-nav')
      this.gNavWrap = document.querySelector('.nav-wrap')
      this.enHeader = document.querySelector('.en-header__inner')
      this.ticking = false;
      if(this.gNav) {
          document.addEventListener('scroll', () => {
              if (!this.ticking) {
                  requestAnimationFrame(() => {
                  this.ticking = false;
                  this.headerElem.style.left = -window.pageXOffset + "px";
                  this.gNav.style.left = -window.pageXOffset + "px";
                  });
                  this.ticking = true;
              }
          })
      } else if(this.enHeader) {
          document.addEventListener('scroll', () => {
              if (!this.ticking) {
                  requestAnimationFrame(() => {
                  this.ticking = false;
                  this.headerElem.style.left = -window.pageXOffset + "px";
                  });
                  this.ticking = true;
              }
          })
      }
  }
}
