//import MicroModal from 'micromodal';
import imagesLoaded from 'imagesloaded';

export default class Top {
    constructor() {

      let zoomElem = document.querySelector('.fv')
      let eleList = document.querySelectorAll('.fv-bgLayer span')
      let txtElem = document.querySelector('.fv-inner')


      imagesLoaded( zoomElem, function( instance ) {
        for (let i = 0; i < eleList.length; ++i) {

          setTimeout(function(){
            eleList[i].classList.add('is-show');
          },i+'000');
        }
        setTimeout(function(){
          txtElem.classList.add("is-fade");
        },2800);

        setTimeout(function(){
          zoomElem.classList.add("is-zoom");
        },1500);

      });

    }


}


