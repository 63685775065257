

export default class PageNavGlobal {
    constructor() {

      // URL を取得する（対象は第3階層で判別）
      const urlPath = location.pathname;
      const result = urlPath.split('/').filter(Boolean);

      //グローバルナビのリンクを取得
      this.navChildLinks = document.querySelectorAll('.nav-child__link');

      //ページ一覧のナビゲーションを取得
      this.navNewsLinks = document.querySelectorAll('.news-nav_link');



      if(result){



          for(let i = 1; i < this.navChildLinks.length; i++){
            let targetNavChildLink = this.navChildLinks[i].getAttribute('href').split('/').filter(Boolean);

            let nowPageDir = targetNavChildLink[1];

            if(result[1] == nowPageDir){

              this.navChildLinks[i].classList.add('is-show')
              this.navChildLinks[i].closest('.global-nav__item').classList.add('is-nowParentUrl');
              this.navChildLinks[i].closest('.global-nav__item').querySelector('.global-nav__link span').classList.add('is-hover2');

            }

          }





        //news一覧のナビゲーション
        for(let i = 0; i < this.navNewsLinks.length; i++){
          let targetnavNewsLinks = this.navNewsLinks[i].getAttribute('href').split('/').filter(Boolean);

          let nowPageDir = targetnavNewsLinks[1];

          if(result[1] == nowPageDir){

            this.navNewsLinks[i].classList.add('is-show')

          }

        }

      }




    }


}
