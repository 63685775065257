import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class NavGlobal {
    constructor() {
        const breakpoint = window.matchMedia("(min-width:769px)")
        this.gNavWrap = document.querySelector('.nav-wrap')
        this.gNav = document.getElementById('global-nav')
        this.langBtn = document.querySelector('.global-nav__language--plus')

        this.gNavLinks = document.querySelectorAll('.global-nav__link')
        this.gNavChild = document.querySelector('.nav-child')
        this.gNavGrandChilds = document.querySelectorAll('.nav-grand-child')

        this.gNavGrandChild = document.querySelectorAll('.nav-grand-child__item')
        this.gNavGrandChildLinks = document.querySelectorAll('.nav-grand-child__global-link')
        //this.gNavOverlay = document.querySelector('.global-nav__overlay')
        // sp
        this.mNavBtn = document.querySelector('.menu-btn')
        this.html = document.documentElement
        if(this.gNav) {
            this.navControllSp()
            this.lagOpenMenu(this.langBtn)
        }

        const breakpointCheck = () => {

            if (breakpoint.matches === true && this.gNav ) {
                this.html.style.overflow = ''
                clearAllBodyScrollLocks(this.gNav)
                this.navControll(this.gNavWrap)


            }else{


              Array.prototype.forEach.call(this.gNavLinks, function(allgNavLinks) {
                allgNavLinks.classList.remove('is-open')
              })

            }
        }
        if (this.html) {
            breakpoint.addListener(breakpointCheck);
            breakpointCheck();
        }
    }



    navControll() {
        this.gNavWrap.classList.remove('is-show')
        //this.gNavOverlay.classList.remove('is-show-sp')
        this.mNavBtn.classList.remove('is-active')

        let body_id = document.body.getAttribute('id')

        Array.prototype.forEach.call(this.gNavLinks, function(gNavLink) {
          if(gNavLink.classList.contains('is-open')) {
            gNavLink.classList.remove('is-open')

          }
          if(gNavLink) {
              gNavLink.parentNode.addEventListener('mouseenter', e => {
                  if(e.target) {
                      //this.gNavOverlay.classList.add('is-show')
                      gNavLink.querySelector('span').classList.add('is-hover')

                  }

                  if(body_id == 'top' || body_id == 'contact' || body_id == 'news'){
                    return;
                  }else{
                    document.querySelector('.is-nowParentUrl > .global-nav__link > span').classList.remove('is-hover2')
                  }


              })
              gNavLink.parentNode.addEventListener('mouseleave', e => {
                  if(e.target) {
                      //this.gNavOverlay.classList.remove('is-show')
                      gNavLink.querySelector('span').classList.remove('is-hover')

                  }

                  if(body_id == 'top' || body_id == 'contact' || body_id == 'news'){
                    return;
                  }else{
                    document.querySelector('.is-nowParentUrl > .global-nav__link > span').classList.add('is-hover2')
                  }



              })
          }

        })



        Array.prototype.forEach.call(this.gNavGrandChilds, function(gNavGrandChild) {
          if(gNavGrandChild) {
            gNavGrandChild.addEventListener('mouseenter', e => {
              if(e.target) {
                gNavGrandChild.parentNode.querySelector('.nav-child__link').classList.add('is-show')
              }

            })
            gNavGrandChild.addEventListener('mouseleave', e => {
              if(e.target) {
                gNavGrandChild.parentNode.querySelector('.nav-child__link').classList.remove('is-show')
              }
            })
          }
        })



    }
    navControllSp() {
      Array.prototype.forEach.call(this.gNavLinks, function(gNavLink) {
            gNavLink.addEventListener('click', (e) => {
                if(e.currentTarget.classList.contains('global-nav__link--plus')) {
                    e.currentTarget.classList.toggle('is-open')
                }
            })
        })
        Array.prototype.forEach.call(this.gNavGrandChilds, function(gNavGrandChild) {
          gNavGrandChildLink.addEventListener('click', (e) => {
              if(e.currentTarget.classList.contains('nav-grand-child__global-link--plus')) {
                  e.currentTarget.classList.toggle('is-open')
              }
          })
        })
        this.mNavBtn.addEventListener('click', (e) => {
            e.preventDefault();

            if(this.mNavBtn.classList.contains('is-active')) {
                this.html.style.overflow = ''
                this.mNavBtn.classList.remove('is-active')
                //this.gNav.classList.remove('is-show')
                this.gNavWrap.classList.remove('is-show')
                //this.gNavOverlay.classList.remove('is-show-sp')
                //this.isOpen = 0
                //clearAllBodyScrollLocks(this.gNav)
            } else {
                this.html.style.overflow = 'hidden'
                this.mNavBtn.classList.add('is-active')
                //this.gNav.classList.add('is-show')
                this.gNavWrap.classList.add('is-show')
                //this.gNavOverlay.classList.add('is-show-sp')
                //this.isOpen = 1

                //disableBodyScroll(this.gNav)
            }
        })
    }


    lagOpenMenu(){
      this.langBtn.addEventListener('click', (e) => {
        this.langBtn.classList.toggle('is-open')
        this.langBtn.nextElementSibling.classList.toggle('is-show')
      })

    }
}
