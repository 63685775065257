export default class tab {
  constructor() {
    this.tabBlock = document.querySelectorAll('.tab-block');

    // タブ切り替え

    for(let i = 0; i < this.tabBlock.length; i++) {
      const elemTabBlock = this.tabBlock[i],
            tabLists = elemTabBlock.getElementsByClassName('tab'),
            tabContents = elemTabBlock.getElementsByClassName('contents-area');

      for(let j =0; j < tabLists.length; j++){
        const target = tabLists[j];

        target.addEventListener('click', ()=> {
          //タグ切り替え関数
          tabChange(tabLists,tabContents,target);
        })

      }
    }



    function tabChange(_tabLists,_tabContents,_target){

      const jsActive = 'js-active';
      const jsShow = 'js-show';


      const arrayTabLists = Array.prototype.slice.call(_tabLists),
            arrayTabContents = Array.prototype.slice.call(_tabContents);

      arrayTabLists.forEach(function(selectTab) {

        if(selectTab.classList.contains(jsActive) == true){
          selectTab.classList.remove(jsActive);
        }

      });


      arrayTabContents.forEach(function(selectContent,_tabContents) {

        if(selectContent.classList.contains(jsShow) == true){
          selectContent.classList.remove(jsShow);
        }



      });

      const index = arrayTabLists.indexOf(_target);
      _target.classList.add(jsActive);
      _tabContents[index].classList.add(jsShow);

    };


  }



}
