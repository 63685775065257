
import ScrollHint from 'scroll-hint';

export default class ScrollAction {
    constructor() {

      window.onload = function(){

        let scrollHintText = document.querySelector('.scroll-hint-text')

        if(scrollHintText !== null ){

          scrollHintText.innerText = "横にスクロール\nしてください"

        }else{
          return
        }

      }



    }


}


new ScrollHint('.js-scrollable');
