import NavGlobal from './utils/NavGlobal';
import PageNavGlobal from './utils/PageNavGlobal';
import NewsPageNav from './utils/NewsPageNav';
import Top from './utils/Top';
import HeaderHorizonScroll from './utils/HeaderHorizonScroll';
import ScrollAction from './utils/ScrollAction';
import Modal from './utils/Remodal';
import Tab from './utils/Tab';
import Contact from './utils/Contact';
import Accordion from './utils/Accordion';
import Slider from './utils/Slider';
//import Admin from './utils/Admin';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

import SmoothScroll from 'smooth-scroll';

// import 'slick-carousel';





export default class Main {
  constructor() {
    this.getId()
    this.init()

  }

  init() {
    new NavGlobal();
    new PageNavGlobal();

    new HeaderHorizonScroll();
    new ScrollAction();
    new Modal();
    new Tab();

    new Accordion();
    new Slider();
    //new Admin();

  }

  getId() {
    let body_id = document.body.getAttribute('id')

    switch(body_id) {
      case 'top':
        new Top();
        break;
      case 'contact':
        new Contact();
        break;
      case 'news':
        new NewsPageNav();
        break;

      default:
    }
  }


}

window.addEventListener('DOMContentLoaded', () => {
  new Main()

  new SmoothScroll('a[href*="#"]',{
    header: '[data-scroll-header]',
    speed: 350
  })

  $(document).ready(function() {
    var pagetop = $('.pagetop');
      $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
              pagetop.fadeIn();
          } else {
              pagetop.fadeOut();
              }
          });
          pagetop.click(function () {
              $('body, html').animate({ scrollTop: 0 }, 500);
                return false;
      });
  });



})


